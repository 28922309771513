<template>
  <div class="home">
    <!--    <top-bar></top-bar>-->
    <nav-bar :id="0"></nav-bar>
    <van-popup v-model="communityShow" position="bottom">
      <van-picker title="请选择" show-toolbar :columns="communityList" value-key="value" position="top"
                  @confirm="communityConfirm" @cancel="communityShow = false" />
    </van-popup>
    <!-- 二维码弹窗 -->
    <van-dialog v-model="qrcodeShow" :show-cancel-button="false" :show-confirm-button="false">
      <div class="qrcode-box">
        <img
            class="qrcode-close"
            src="@/assets/img/newVersion/close.png"
            @click="closeQrcode">
        <van-image class="qrcode-img" :src="qrcodeImg" />
        <div class="qrcode-text">自主登记二维码</div>
      </div>
    </van-dialog>
    <div class="banner">
      <img :src="strangeBack" alt=""/>
      <div class="communityShow" @click="changeCommunity">
        <span>{{communityName}}</span>
        <van-icon name="arrow-down" size=".4rem"/>
      </div>
      <span class="title">居民果果</span>
      <div class="center-func">
        <div class="repair" v-for="(item, index) in centerList" :key="index" @click="functionClick(item)">
          <span>{{ item.name}}</span>
          <span>{{index == 0 ? '物业报修' : '拍照上报'}}问题</span>
          <img :src="item.icon" alt=""/>
          <img :src="myApplyIcon" v-if="index == 1" class="myApplyIcon"/>
        </div>
      </div>
    </div>
    <div class="noticeBar" v-if="control.isNoticeBar" @click="useClick({code: 'perfectInfo'})">
      <img :src="noticeBar" alt=""/>
      <span>您的身份证信息待完善</span>
      <span>去完善</span>
    </div>
    <div class="basic">
      <van-grid :border="false" square :column-num="3" :gutter="12">
        <van-grid-item v-for="(item, index) in basicList" :key="index" @click="functionClick(item)">
          <img :src="item.icon" alt="" />
          <span style="text-align:center;font-size: 20px;">{{item.name}}</span>
        </van-grid-item>
        <van-grid-item @click="functionClick({code: 'common-func', url:''})">
          <img :src="configImg" alt="" />
          <span style="text-align:center;font-size: 20px;">配置功能</span>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="my-home">
      <div class="guideTitle" @click="houseClick(null)">
        <span>我的房屋</span>
        <van-icon name="arrow" size=".42rem"/>
      </div>
      <div class="home-content" v-if="control.isHouse">
        <div class="family-left" v-for="(item, index) in houseList" key="index">
          <span class="family-text" @click="houseClick(item.id)" style="color: rgba(155,91,64,0.7)">居住{{item.liveNum}}人 流动{{item.migrantNum}}人</span>
          <span class="family-text" @click="houseClick(item.id)" style="top: 1.3rem;font-size:18px;color:#9B5B40;">{{ item.fullName }}</span>
          <span class="family-text" @click="houseClick(item.id)" style="top:2rem;color:#9B5B40;font-size:18px;}">{{ item.subareaName }}</span>
          <img :src="family3" class="family3" alt=""/>
          <img class="familyCode" @click.stop="openQrcode(item.id,item.relationship)" :src="orangeCode"/>
        </div>
      </div>
      <div v-else>
        <img :src="houseEmpty" class="houseEmpty"/>
        <div class="tip">暂无添加房屋哦～</div>
      </div>
    </div>
    <div class="conduct-content">
      <img :src="adImg" alt=""/>
      <div v-for="(item, index) in noticeList" :key="index" @click="functionClick">
        <div v-if="control.isNotice && item.content.length > 0" class="noticeTxt">
          <div v-for="(items, idx) in item.content" :key="idx" :style="{top: index == 1 ? '112rpx;' : ''}">
            <span>{{items.title}}</span>
          </div>
        </div>
        <div class="noticeTxt" v-else style="padding:24px 0 28px 24px">
          <span class="noneNum">暂无公告哦~</span>
        </div>
      </div>
    </div>
    <div class="usePhone">
      <div class="guideTitle" @click="useClick({url: '',code: 'phone'})">
        <span>常用电话</span>
        <van-icon name="arrow" size=".42rem"/>
      </div>
      <div class="phone-content" v-if="control.isPhone">
        <div class="content-item" v-for="(item, index) in phoneList" :key="index" :style="{'border;': (index == phoneList.length - 1) ? 'none;' : ''}">
          <span>{{item.name}}</span>
          <div class="mobile">{{item.mobile}}</div>
          <a :href="'tel:'+item.mobile" @click.stop=""> <img :src="phoneOld" alt=""/></a>
        </div>
      </div>
      <div class="noneNum" style="padding: 0.32rem 0 0.4rem 0.32rem;" v-else>
        <span>暂无常用电话哦~</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import topBar from '@/components/topBar/topBar'
import navBar from '@/components/navBar/navBar'
import { getImageStream } from '@/utils/index'
import { isPhone, isNULL,IdentityCodeValid } from '@/utils/validate'
import Vue from 'vue'

export default {
  name: 'Home',
  data () {
    return {
      userName:'',
      page: 1,
      limit: 1,
      centerList: [{
        url: '',
        appId: 0,
        code: 'sjgl_bxjl',
        name: '随手报',
        num: 0,
        icon: getImageStream('files/wx/images/jmgg/myRepair.png'),
      }, {
        url: '',
        appId: 0,
        code: 'sjgl_wtjl',
        name: '随手拍',
        num: 0,
        icon: getImageStream('files/wx/images/jmgg/myApply.png'),
      }],
      basicList: [],
      gridList: [{
        url: '/pages/web/index?src=userRes-info&name=居民',
        name: '居民管理',
        icon: getImageStream('files/wx/images/jmgg/peopleIcon.png'),
      },
        {
          url: '/pages/web/index?src=house&name=房屋',
          name: '房屋管理',
          icon: getImageStream('files/wx/images/jmgg/houseIcon.png'),
        },
        {
          url: '/pages/web/index?src=appSSO&name=积分通&curUrl=integral&appId=integral',
          name: '走访记录',
          icon: getImageStream('files/wx/images/jmgg/visitIcon.png'),
        },
        {
          url: '/pages/web/index?src=appSSO&name=服务预约及评价&curUrl=service&appId=communityService&code=communityService',
          code: 'communityService',
          name: '车辆管理',
          icon: getImageStream('files/wx/images/jmgg/carIcon.png'),
        }],
      noticeList: [
        {
          name: '',
          content: [],
          code: 'sqgg',
          url: '',
          icon: '',
        }
      ],
      activityList: [],
      communityName: '',
      strangeBack: '',
      noticeBar: '',
      communityShow: false,
      communityList: [],
      family1: '',
      family2: '',
      family3: '',
      houseEmpty: '',
      orangeCode: '',
      blueCode: '',
      greenCode: '',
      questionAd: '',
      signUp: '',
      activity: '',
      phoneIcon: '',
      phoneList: [],
      questionList: [],
      houseList: [],
      activityUrl: '',
      phoneUrl: '',
      questionUrl: '',
      configImg: '',
      myApplyIcon: '',
      activityDefault: '',
      activityEmpty: '',
      activityAppId: null,
      qrcodeShow: false,
      qrcodeImg: '',
      adImg: '',
      phoneOld: '',
      control: {
        showCert: false,
        codeVisible: false,
        isRepair: false,
        isProblem: false,
        isNotice: false,
        isWork: false,
        isActivity: false,
        isPhone: false,
        isQuestion: true,
        isInfo: false,
        isNoticeBar: false,
        isHouse: false
      },
    }
  },
  components: {
    topBar,
    navBar
  },
  methods: {
    init () {
      this.communityName = this.$globalData.userInfo.communityName
      this.strangeBack = getImageStream('files/wx/images/jmgg/strangeBack.png')
      this.noticeBar = getImageStream('files/wx/images/jmgg/noticeBar.png')
      this.family1 = getImageStream('files/wx/images/jmgg/family1.png')
      this.family2 = getImageStream('files/wx/images/jmgg/family2.png')
      this.family3 = getImageStream('files/wx/images/jmgg/family3.png')
      this.orangeCode = getImageStream('files/wx/images/jmgg/orangeCode.png')
      this.blueCode = getImageStream('files/wx/images/jmgg/blueCode.png')
      this.greenCode = getImageStream('files/wx/images/jmgg/greenCode.png')
      this.questionAd = getImageStream('files/wx/images/jmgg/questionAd.png')
      this.signUp = getImageStream('files/wx/images/jmgg/signIcon.png')
      this.activity = getImageStream('files/wx/images/jmgg/activityIcon.png')
      this.phoneIcon = getImageStream('files/wx/images/jmgg/phoneIcon.png')
      this.configImg = getImageStream('files/wx/images/jmgg/funcIcon.png')
      this.myApplyIcon = getImageStream('files/wx/images/jmgg/myApplyIcon.png')
      this.family1 = getImageStream('files/wx/images/jmgg/family1.png')
      this.family2 = getImageStream('files/wx/images/jmgg/family2.png')
      this.family3 = getImageStream('files/wx/images/jmgg/family3.png')
      this.houseEmpty = getImageStream('files/wx/images/jmgg/houseEmpty.png')
      this.activityDefault = getImageStream('files/wx/images/jmgg/activityDefault.png')
      this.activityEmpty = getImageStream('files/wx/images/jmgg/activityEmpty.png')
      this.adImg = getImageStream('files/wx/images/jmgg/adOld.png')
      this.phoneOld = getImageStream('files/wx/images/jmgg/phoneOld.png')
      this.getCommonList()
      this.getQuestion()
      this.getHouse()
      this.getJmggInfo()
    },
    //居民信息
    getJmggInfo(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/jmggDetailInfo'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          let isCheck = false
          let user  = data.userInfoEntity
          if(user.idNumberType == 1 && !IdentityCodeValid(user.idNumber)) {
            isCheck = true
          }
          if(!isPhone(user.mobile)) {
            isCheck = true
          }
          if (isNULL(user.userName) || isNULL(user.registryType) || isNULL(user.idNumber) || isNULL(user.mobile) || isNULL(user.birthday)) {
            isCheck = true
          }
          if(isCheck) {
            this.control.isNoticeBar = true
          }
        }
      })
    },
    //获取常用功能
    getCommonList(){
      let centerList = []
      let noticeList = []
      let activityList = []
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/message/remind/config/jmgg/myCommonMenuList'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$orgId,
          userId: this.$globalData.userInfo.userId
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          data.allMyList.list.map(item => {
            if(item.appCode && item.appCode == 'sjgl_hdjl') {
              this.activityUrl = item.pageUrlProgram
            }
            item.pageUrlProgram = item.pageUrlProgram.indexOf('/') == 0 ? '/' +item.pageUrlProgram.split('/')[1] :
                item.pageUrlProgram.indexOf('http') == 0 ? '/appSSO?url=' + encodeURIComponent(item.pageUrlProgram) +
                    '&appCode=' + item.appCode + '&appId=' + item.appId + '&appName=' + item.appName : ''
            if(item.appCode && item.appCode == 'sjgl_bxjl') {
              this.control.isRepair = true
              this.getRepairList()
            }
            if(item.appCode && item.appCode == 'sjgl_wtjl') {
              this.control.isProblem = true
              this.getProblemList()
            }
            if(item.appCode && item.appCode == 'sqgg') {
              this.control.isNotice = true
              this.getNoticeList()
            }
            if(item.appCode && item.appCode == 'yy_bszn') {
              this.control.isWork = true
              this.getWorkList()
            }
            if(item.appCode && item.appCode == 'sjgl_hdjl') {
              this.control.isActivity = true
              this.getActivity()
            }
            if(item.appCode && item.appCode == 'bmdhb') {
              this.control.isPhone = true
              this.phoneUrl = item.pageUrlProgram
              this.getPhone()
            }
            if(item.appCode && item.appCode == 'question') {
              this.control.isQuestion = true
              this.questionUrl = item.pageUrlProgram
            }
          })
          this.centerList.map(itemss => {
            data.allMyList.list.map(items =>{
              if((itemss.code == items.appCode) && data.allMyList.list.length > 0) {
                itemss.url = items.pageUrlProgram
                itemss.appId = items.appId
                centerList.push(itemss)
              }
            })
          })
          this.noticeList.map(itemss => {
            data.allMyList.list.map(items =>{
              if(itemss.code == items.appCode) {
                itemss.url = items.pageUrlProgram
                itemss.appId = items.appId
                noticeList.push(itemss)
              }
            })
          })
          this.activityList.map(itemss => {
            data.allMyList.list.map(items =>{
              if((itemss.code == items.appCode) && data.allMyList.list.length > 0) {
                itemss.menuId = items.appId
                this.activityAppId = items.appId
                activityList.push(itemss)
              }
            })
          })
          this.centerList = centerList.length > 0 ? [...new Set(centerList)] : this.centerList
          this.noticeList = noticeList.length > 0 ? [...new Set(noticeList)] : this.noticeList
          this.activityList = activityList.length > 0 ? [...new Set(activityList)] : this.activityList
          this.basicList = data.myList && data.myList.length > 0 ? data.myList.map(element => {
            return {
              icon: getImageStream(element.icon),
              name: element.name,
              url: element.url.indexOf('/') == 0 ? '/' +element.url.split('/')[1] :
                  element.url.indexOf('http') == 0 ? '/appSSO?url=' + encodeURIComponent(element.url) +
                      '&appCode=' + element.code + '&appId=' + element.menuId + '&appName=' + element.name : '',
              appId: element.menuId,
              code: element.code
            }
          }) : []
        }
      })
    },
    //维修总数
    getRepairList(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/repair/info/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit:1,
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.centerList.map(item => {
            if(item.code == 'sjgl_bxjl') {
              item.num = data.page.totalCount
            }
          })
        }
      })
    },
    //问题总数
    getProblemList(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: 1,
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.centerList.map(item => {
            if(item.code == 'sjgl_wtjl') {
              item.num = data.list.totalCount
            }
          })
        }
      })
    },
    // 社区活动数
    getActivity(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/nowList'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: 2,
          orgId: this.$orgId,
          orgUserId:this.$globalData.userInfo.userId,
          surveyObject: 1,
          putaway: 1,
          logInOrgId: this.$orgId,
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.activityList.map((item, index) => {
            data.page.list.map((items, idx) => {
              if(index == idx) {
                item.name = items.title
                item.progressStr = items.progressStr
                item.id = items.id
                if(items.imagePath && items.imagePath !== '0') {
                  item.icon = getImageStream(items.imagePath.replace(/\\/g, "/"))
                } else {
                  item.icon = this.activityDefault
                }
              }
            })
          })
        }
      })
    },
    // 公告
    getNoticeList(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/notice/newList'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: 2,
          logInOrgId: this.$orgId,
          orgUserId: this.$globalData.userInfo.userId,
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.noticeList.map(item => {
            if(item.code == 'sqgg') {
              item.content = data.page.list
            }
          })
        }
      })
    },
    // 办事指南
    getWorkList(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/guide/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: 2,
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId,
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.noticeList.map(item => {
            if(item.code == 'yy_bszn') {
              item.content = data.page.list
            }
          })
        }
      })
    },
    // 问卷
    getQuestion(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/questionnaire/publicity'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,          //当前页码
          limit: -1,        //每页展示的记录数
          orgId:this.$orgId,
          userId:this.$globalData.userInfo.userId,
          surveyObject: 1,
          status: 1,
          canAnswer: 1,
          putaway: 1
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          data.page.list.map(item => {
            if(item.isAnonymous == 0 || (item.participate && !item.participate)) {
              this.questionList.push({
                title: item.shortName.split('').join('/'),
                img: this.questionAd,
                url: '/questionWrite?ids=' + item.id
              })
            }
          })
        }
      })
    },
    // 常用电话
    getPhone(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/appconveniencephonebook/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,          //当前页码
          limit: -1,        //每页展示的记录数
          orgId:this.$orgId
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.phoneList = data.page.list
        }
      })
    },
    // 我的房屋
    getHouse(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,          //当前页码
          limit: 4,        //每页展示的记录数
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId,
          loginUserId: this.$globalData.userInfo.userId,
          loginUserOrgId: this.$globalData.userInfo.orgId
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.houseList = data.page.list
          this.control.isHouse = data.page.list.length > 0 ? true : false
        }
      })
    },
    changeCommunity(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/list/communityByStreet'),
        method: 'post',
        params: this.$http.adornParams({
          userId: this.$globalData.userInfo.userId,
          idNumber: null,
          street: this.$globalData.userInfo.street,
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          if (data.list.length == 1) {
            this.$toast.fail({
              message: '您在其它社区没有录入信息,有问题请联系管理员!',
              className: 'failToast'
            })
          } else {
            this.communityList = data.list
          }
        } else {
          this.$toast.fail({
            message: '您在其它社区没有录入信息,有问题请联系管理员!',
            className: 'failToast'
          })
        }
      })
    },
    communityConfirm(value){
      this.$dialog.confirm({
        title: '提示',
        message: '确定更换社区?',
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/updateTwoDomainLogin'),
          method: 'post',
          params: this.$http.adornParams({
            userId: this.$globalData.userInfo.userId,
            communityId: value.value,
            topToken: this.$cookies.get('token'),
          })
        }).then(({data})=> {
          if (data && data.code == 0) {
            if (data.homePageVO.domain) {

            } else {
              this.communityShow = false
              this.$globalData.userInfo.userId = data.homePageVO.userId
              this.$orgId = value.value
              this.$orgName = value.label
            }
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }).catch(() => {
        this.communityShow = false
      });
    },
    houseClick(houseId){
      if(houseId) {
        this.$router.push({path: 'peopleList', query: {id: houseId}})
      } else {
        this.$router.push('/houseManagement')
      }
    },
    useClick(data){
      let url = data.url
      let code = data.code
      if(code == 'question'){
        this.$router.push(url)
      } else if(code == 'sjgl_hdjl') {
        if (this.control.isActivity) {
          if (data.id) {
            let activityUrl = this.activityUrl.split('?')[0] + '?url=' + 'activityInfo'
            let toUrl = '/appSSO?url=' + encodeURIComponent(activityUrl) +
                '&appCode=' + code + '&appId=' + data.appId + '&appName=' + '活动' + '&id=' + data.id
            Vue.prototype.$appId = data.appId
            this.$router.push(toUrl)
          } else {
            Vue.prototype.$appId = this.activityAppId
            this.$router.push(this.activityUrl)
          }
        } else {
          this.$toast({
            message: '功能暂未开通!',
            icon: 'none',
            duration: 2000//持续的时间
          })
        }
      } else if(code == 'phone') {
        if (this.control.isPhone) {
          this.$router.push('/phoneBook')
        } else {
          this.$toast({
            message: '功能暂未开通!',
            icon: 'none',
            duration: 2000//持续的时间
          })
        }
      } else if(code == 'perfectInfo') {
        this.$router.push('/my-info-change')
      }
    },
    functionClick(data){
      let code = data.code
      let url = data.url
      if(code == 'sjgl_bxjl') {
        if(this.control.isRepair) {
          if (url.indexOf('http') !== -1) {
            Vue.prototype.$appId = data.appId
            this.$router.push(url)
          }
        } else {
          this.$toast({
            message: '功能暂未开通!',
            icon: 'none',
            duration: 2000//持续的时间
          })
        }
      } else if(code == 'sjgl_wtjl') {
        if(this.control.isProblem) {
          if (url.indexOf('http') !== -1) {
            Vue.prototype.$appId = data.appId
            this.$router.push(url)
          } else {
            this.$router.push(url)
          }
        } else {
          this.$toast({
            message: '功能暂未开通!',
            icon: 'none',
            duration: 2000//持续的时间
          })
        }
      } else if(code == 'sqgg') {
        if(this.control.isNotice) {
          if (url.indexOf('http') !== -1) {
            Vue.prototype.$appId = data.appId
            this.$router.push(url)
          } else {
            this.$router.push(url)
          }
        } else {
          this.$toast({
            message: '功能暂未开通!',
            icon: 'none',
            duration: 2000//持续的时间
          })
        }
      } else if(code == 'yy_bszn') {
        if(this.control.isWork) {
          if (url.indexOf('http') !== -1) {
            Vue.prototype.$appId = data.appId
            this.$router.push(url)
          } else {
            this.$router.push(url)
          }
        } else {
          this.$toast({
            message: '功能暂未开通!',
            icon: 'none',
            duration: 2000//持续的时间
          })
        }
      } else if(code == 'sjgl_hdjl') {
        if(this.control.isActivity) {
          if (url.indexOf('http') !== -1) {
            Vue.prototype.$appId = data.appId
            this.$router.push(url)
          } else {
            this.$router.push(url)
          }
        } else {
          this.$toast({
            message: '功能暂未开通!',
            icon: 'none',
            duration: 2000//持续的时间
          })
        }
      } else if(code == 'bmdhb') {
        if(this.control.isPhone) {
          this.$router.push('/phoneBook')
        } else {
          this.$toast({
            message: '功能暂未开通!',
            icon: 'none',
            duration: 2000//持续的时间
          })
        }
      } else if(code == 'common-func') {
        this.$router.push('/common-func')
      } else {
        Vue.prototype.$appId = data.appId
        this.$router.push(url)
      }
    },
    // 打开二维码弹窗
    openQrcode(houseId,relationship) {
      let isTenant = 0
      if(relationship == 10) {
        isTenant = 1
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/getRegisterQrCode'),
        method: 'POST',
        params: this.$http.adornParams({
          community: this.$orgId,
          houseId,
          isTenant,
          userId: this.$globalData.userInfo.userId,

        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.qrcodeImg = getImageStream(data.qrCode)
          this.qrcodeShow = true
        }
      })
    },
    // 关闭二维码弹窗
    closeQrcode() {
      this.qrcodeShow = false
    },
    callPhone(){}
  },
  created () {
    this.init()
  }
}
</script>
<style>
.van-grid-item__content {
  padding: 0;
}
.failToast {
  width: 300px !important;
}
</style>
<style lang="scss" scoped>
.home {
  overflow-y: scroll;
  height: 90vh;
  //min-height: calc(100vh - 286px);
}
.banner {
  position: relative;
  img:first-child {
    width: 100%;
    height: 376px;
  }
  .communityShow {
    position: absolute;
    top: 108px;
    left: 30px;
    display: flex;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    .van-icon {
      padding: 5px 0 0 4px;
    }
  }
  .title {
    position: absolute;
    top: 108px;
    left: 40%;
    font-size: 34px;
    color: #333333;
    font-family: 'PingFang Bold';
    font-weight: 600;
  }
  .center-func {
    position: absolute;
    top: 216px;
    left: 30px;
    width: 690px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .repair {
      position: relative;
      span {
        position: absolute;
        top: 26px;
        left: 20px;
        color: #FFFFFF;
        font-size:40px;
        &:first-child {
          padding-bottom: 5px;
        }
        &:nth-child(2) {
          top: 74px;
          font-size:28px;
          color: #fff;
        }
      }
      img {
        width: 334px;
        height: 136px;
      }
      .myApplyIcon {
        position: absolute;
        top: -10px;
        right: -25px;
        width: 166px;
        height: 168px;
      }
    }
  }
}
.noticeBar {
  position: relative;
  width: 750px;
  height: 75px;
  margin-top: -8px;
  background: #FFF9ED;
  img {
    width: 36px;
    height:36px;
    position:absolute;
    top:20px;
    left:30px;
  }
  span {
    position: absolute;
    top: 20px;
    left: 78px;
    font-size: 32px;
    font-family: 'PingFangSC-Regular';
    color: #FF6010;
    &:last-child {
      left: unset;
      right: 30px;
    }
  }
}
.my-home {
  position: relative;
  width: 692px;
  max-height: 524px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 24px 0 0 30px;
  .home-content {
    position: relative;
    max-height:524px;
    display: flex;
    padding: 18px 26px 0 24px;
    justify-content: space-between;
    flex-wrap: wrap;
    .family-left {
      width: 310px;
      height: 194px;
      margin-bottom: 24px;
      img {
        width: 310px;
        height: 194px;
      }
      .family3 {
        width: 644px;
      }
      .family-text {
        position:absolute;
        top:45px;
        left:54px;
        width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size:32px;
        font-weight: 500;
        color:rgba(74,94,135,0.7);
      }
      .familyCode {
        position:absolute;
        top:16px;
        right:20px;
        width:76px !important;
        height:76px !important;
      }
    }
  }
  .houseEmpty {
    height:408px;
    padding-left:136px;
  }
  .tip {
    position:absolute;
    left: 38%;
    bottom:20px;
    font-size:24px;
    color:#999;
  }
  //.guideContent {
  //  display: flex;
  //  margin: 20px 0 0 24px;
  //  justify-content: space-between;
  //  flex-wrap: wrap;
  //  .guideItem {
  //    position: relative;
  //    img {
  //      width: 310px;
  //      height: 194px;
  //    }
  //    .family3 {
  //      width: 644px;
  //      height: 194px;
  //    }
  //    .family-text {
  //      position:absolute;
  //      top:28px;
  //      left:16px;
  //      font-size:24px;
  //      color:rgba(74,94,135,0.7);
  //      &:nth-child(2) {
  //        top: 74px;
  //        color:#4A5E87;
  //        font-size:32px;
  //      }
  //      &:nth-child(3) {
  //        top: 122px;
  //        color:#4A5E87;
  //        font-size:32px;
  //      }
  //    }
  //    .familyCode {
  //      position:absolute;
  //      top:8px;
  //      right:4px;
  //      width:64px !important;
  //      height:64px !important;
  //    }
  //  }
  //}
}
.grid-manage {
  width: 690px;
  height: 294px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 24px 0 0 30px;
  .grid-content {
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width:642px;
    height:186px;
    margin:28px 0 0 24px;
  }
  .gridMode {
    position: relative;
    width: 310px;
    height: 76px;
    background-color: #F8F9FD;
    img {
      position: absolute;
      top: 12px;
      left: 50px;
      width: 52px;
      height: 52px;
    }
    span {
      position: absolute;
      top: 18px;
      left: 122px;
      font-size: 28px;
      color: #333333;
    }
    .van-icon {
      position: absolute;
      top: 22px;
      right: 32px;
      color: #999999;
    }
  }
}
.conduct-content {
  position: relative;
  width: 750px;
  padding: 24px 30px;
   img{
    width: 690px;
    height: 168px;
  }
  .noticeTxt {
    position: absolute;
    top: 54px;
    left: 222px;
    width: 468px;
    height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 36px;
    color: #333333;
  }
  .workGuide {
    position:absolute;
    top: 98px;
    left: 16px;
    width:302px;
    height:140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 8px;
    background: rgba(255,255,255,0.7);
    font-size: 28px;
  }
}
.questionAd{
  margin: 24px 30px 0;
  border-radius: 20px;
  overflow: hidden;
  width: 690px;
  height: 136px;
  box-sizing: border-box;
  .questionSwiper {
    position: relative;
    //height: 136px;
    img {
      width: 690px;
      height: 136px;
    }
  }
  .banner-title-question {
    span {
      position: absolute;
      top: 18px;
      left: 186px;
      font-size: 52px;
      font-family: PangMenZhengDao;
      font-weight: normal;
      color: #4581F8;
      line-height: 60px;
      text-shadow: 0px 4px 8px rgba(69, 129, 248, 0.5);

      &:last-child {
        position: absolute;
        top: 82px;
        left: 240px;
        line-height: 40px;
        font-size: 28px;
        color: #4D82EE;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        text-shadow: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}
.activity-manage {
  width: 690px;
  height: 362px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 24px 0 0 30px;
  .activity-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    .activity-item {
      margin-top: 42px;
      &:first-child {
        margin-right: 20px;
      }
      ::v-deep .van-image__img {
        position:relative;
        width:310px;
        height:174px;
      }
      .activityBack {
        position:absolute;
        top:12px;
        left:12px;
        background: linear-gradient(90deg, #F08687 0%, #E9564B 100%);
        border-radius: 4px 4px 12px 4px;
      }
      .activityText {
        font-size:20px;
        color:#ffffff;
        padding: 4px 12px;
      }
    }
  }
}
.usePhone {
  width: 690px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 24px 0 70px 30px;
  .phone-content {
    margin:12px 24px 24px;
    overflow-y:scroll;
    height:298px;
  }
  .content-item {
    display: flex;
    align-items: center;
    line-height: 80px;
    border-bottom: 1px solid #EDEDED;
    font-size: 36px;
    margin-bottom: 14px;
    span {
      color: #333333;
      font-family: 'PingFang Bold';
    }
    .mobile {
      flex: 1;
      text-align: right;
      margin-right:24px;
      color:#999;
    }
    img {
      width: 48px;
      height: 48px;
    }
  }
}
.guideTitle {
  padding: 20px 0 0 24px;
  span {
    font-size: 36px;
    font-weight: 600;
  }
  .van-icon {
    float: right;
    margin-right: 26px;
    color: #D1D1D6;
  }
}
.noneNum {
  font-size: 30px;
  margin: 50px auto 0;
}
.activityEmpty {
  position:absolute;
  left:216px;
  width:260px;
  height:254px;
}
.emptyText {
  position:absolute;
  left:41%;
  top:200px;
  font-size:24px;
  color:#999;
}
.qrcode-box {
  /* width: 560px; */
  height: 591px;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  /* 样式qrcode-close的父 相对定位 */
  position: relative;
}
.qrcode-close {
  /* 样式qrcode-box的子 绝对定位 */
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
}
.qrcode-img {
  width: 360px;
  height: 360px;
  margin-top: 70px;
  margin-bottom: 40px;
}
.qrcode-text {
  font-size: 36px;
  font-family: 'PingFangSC-Medium';
  font-weight: 500;
  color: #333333;
  margin-bottom: 70px;
}
.basic {
  //position: absolute;
  //top: 396px;
  //left: 0;
  width: 720px;
  font-size: 28px;
  margin: 26px 30px 0 18px;
  ::v-deep .van-grid-item {
    //margin-right: 20px;
    background-color: transparent;
  }
  ::v-deep .van-grid-item__content {
    border-radius: 20px;
    padding-top: 20px;
  }
  ::v-deep .van-grid__inner {
    padding-bottom: 0 !important;
  }
  img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
  span {
    display:inline-block;
    letter-spacing: -2px;
    color:#333333;
    font-size:28px;
    margin-top: 16px;
  }
  .basic-item {
    text-align: center;
  }
}
</style>
